<template>
  <div>
    <h1>SAMSYS counters: {{ commonCounters.length }}</h1>
    <p>{{ commonCounters }}</p>
  </div>
</template>

<script>
import BleScanServices from '../services/bleScan.services.js'
import dayjs from 'dayjs'
export default {
  name: 'CountersLocation',

  data() {
    return {
      bureau: [],
      atelier: [],
    }
  },

  computed: {
    bureauWithDist() {
      return this.bureau.map((b) => ({
        counter: b.counter,
        distance: b.scan.reduce((acc, curr) => acc + curr.dist, 0),
      }))
    },

    atelierWithDist() {
      return this.atelier.map((a) => ({
        counter: a.counter,
        distance: a.scan.reduce((acc, curr) => acc + curr.dist, 0),
      }))
    },

    commonCounters() {
      const common = []

      this.bureauWithDist.forEach((b) => {
        const atelier = this.atelierWithDist.find((a) => a.counter === b.counter)
        if (atelier) {
          common.push({
            counter: b.counter,
            where: b.distance < atelier.distance ? 'bureau' : 'atelier',
          })
        }
      })

      return common
    },
  },

  mounted() {
    this.getTagScanAtelier()
    this.getTagScanBureau()
  },

  methods: {
    async getTagScanAtelier() {
      const { data } = await BleScanServices.getTagScan(
        '100000c4',
        dayjs().subtract(24, 'hour').toDate(),
        dayjs().endOf('day').toDate()
      )
      this.atelier = data
    },
    async getTagScanBureau() {
      const { data } = await BleScanServices.getTagScan(
        '1000003a',
        dayjs().subtract(24, 'hour').toDate(),
        dayjs().endOf('day').toDate()
      )
      this.bureau = data
    },
  },
}
</script>
