<template>
  <v-card flat>
    <v-card-title>
      Ble Tags
      <v-spacer></v-spacer>
      <v-icon
        @click="selectedMachine = !selectedMachine"
        class="mr-2"
        :color="selectedMachine ? 'green' : 'grey lighten-1'"
        >mdi-tractor</v-icon
      >
      <v-icon
        @click="selectedUser = !selectedUser"
        :color="selectedUser ? 'green' : 'grey lighten-1'"
        >mdi-account</v-icon
      >
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      dense
      :headers="headers"
      :loading="loading"
      :items="filteredTags"
      :items-per-page="10"
      sort-by="t"
      sort-desc>
      <template v-slot:[`item.t`]="{ item }">
        <span v-if="item.t">{{ item.t | moment('lll') }}</span>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-icon
          small
          v-if="item.type === 'machine'"
          >mdi-tractor</v-icon
        >
        <v-icon
          small
          v-else
          >mdi-account</v-icon
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import BleTagsServices from '../services/bleTags.services.js'
export default {
  name: 'Tags',

  data() {
    return {
      tags: [],
      loading: false,
      selectedMachine: true,
      selectedUser: true,
      search: '',
      headers: [
        {
          text: 'Id',
          align: 'left',
          sortable: false,
          value: '_id',
        },
        {
          text: 'type',
          align: 'left',
          sortable: false,
          value: 'type',
        },
        {
          text: 'Counter',
          align: 'right',
          sortable: true,
          value: 'counter',
        },
        {
          text: 'Last_seen',
          align: 'right',
          sortable: true,
          value: 't',
        },
        {
          text: 'vbat (volt)',
          align: 'right',
          sortable: true,
          value: 'v_bat',
        },
        {
          text: 'RSSI',
          align: 'right',
          sortable: true,
          value: 'dist',
        },
        {
          text: 'owner',
          align: 'right',
          sortable: true,
          value: 'owner',
        },
      ],
    }
  },

  computed: {
    selectedTags() {
      return this.tags.filter((tag) => {
        if (this.selectedMachine && tag.type === 'machine') {
          return true
        }
        if (this.selectedUser && tag.type === 'user') {
          return true
        }
        return false
      })
    },

    fomatedTags() {
      const now = new Date()
      return this.selectedTags.map((tag) => {
        const owner = tag.owner?.find(
          (o) => o.start_date < now.toISOString() && (!o.end_date || o.end_date > now.toISOString())
        )
        return {
          ...tag,
          t: tag.lastScan.t,
          counter: tag.lastScan.counter,
          v_bat: tag.lastScan?.v_bat === 255 ? 'NC' : (tag.lastScan.v_bat ?? 0) / 10,
          dist: tag.lastScan.dist,
          owner: owner?.cluster?.name,
        }
      })
    },

    filteredTags() {
      if (this.search === '') {
        return this.fomatedTags
      }

      return this.fomatedTags.filter((tag) => {
        return (
          tag._id.toLowerCase().includes(this.search.toLowerCase()) ||
          tag.owner?.toLowerCase().includes(this.search.toLowerCase()) ||
          tag.counter?.toLowerCase().includes(this.search.toLowerCase())
        )
      })
    },

    counters() {
      return [...new Set(this.tags.map((tag) => tag.lastScan.counter))]
        .filter((c) => c !== undefined)
        .sort((a, b) => (a > b ? 1 : -1))
    },
  },

  async mounted() {
    this.getAllTagsPaginated()
  },

  methods: {
    async getAllTagsPaginated(page = 1, acc = []) {
      this.loading = true
      const { data } = await BleTagsServices.getTags({ limit: 250, page })
      acc = acc.concat(data)
      if (data.length === 250) {
        this.getAllTagsPaginated(page + 1, acc)
        return
      }
      acc = acc.sort((a, b) => ((a.lastScan.t ?? '0000-00') > (b.lastScan.t ?? '0000-00') ? -1 : 1))
      this.tags = acc
      this.loading = false
    },
  },
}
</script>
